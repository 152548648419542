var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drPickWrap" },
    [
      _vm._m(0),
      _c("section", { staticClass: "else-wrap" }, [
        !_vm.save
          ? _c(
              "button",
              { staticClass: "btn-back", on: { click: _vm.historyBack } },
              [_vm._v(" 뒤로 ")]
            )
          : _vm._e(),
        _c("div", { staticClass: "login-wrap" }, [
          _c("h2", [_vm._v("닥터인포")]),
          _vm.step === 1
            ? _c("div", { staticClass: "login-write" }, [_vm._m(1)])
            : _vm._e(),
          _vm.step === 1
            ? _c(
                "button",
                {
                  staticClass: "btn-phone-verification",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.openVerification($event)
                    },
                  },
                },
                [_vm._v(" 휴대폰 인증 ")]
              )
            : _vm.step === 2
            ? _c("div", { staticClass: "login-write" }, [
                _vm.password === null || _vm.password.length < 6
                  ? _c("ul", [
                      _vm._m(2),
                      _c("li", { staticStyle: { "margin-bottom": "162px" } }, [
                        _c("label", { staticClass: "hidden" }, [
                          _vm._v("비밀번호"),
                        ]),
                        _c("div", { staticClass: "text-wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                              { name: "number", rawName: "v-number" },
                              { name: "focus", rawName: "v-focus" },
                            ],
                            attrs: { type: "password", maxlength: "6" },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm.password !== null && _vm.password.length == 6
                  ? _c("ul", [
                      _vm._m(3),
                      _c("li", { staticStyle: { "margin-bottom": "162px" } }, [
                        _c("label", { staticClass: "hidden" }, [
                          _vm._v("비밀번호"),
                        ]),
                        _c("div", { staticClass: "text-wrap" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.checkPassword,
                                expression: "checkPassword",
                              },
                              { name: "number", rawName: "v-number" },
                              { name: "focus", rawName: "v-focus" },
                            ],
                            class: _vm.errorMessage ? "error" : "inputPassword",
                            attrs: {
                              type: "password",
                              maxlength: "6",
                              readonly: _vm.save ? true : false,
                            },
                            domProps: { value: _vm.checkPassword },
                            on: {
                              keyup: _vm.matchPassword,
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.checkPassword = $event.target.value
                              },
                            },
                          }),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm.errorMessage
                  ? _c("div", { staticClass: "message-box" }, [
                      _c("p", [_c("i"), _vm._v(_vm._s(_vm.errorMessage))]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "login-txt" },
            [
              _c("div", [
                _c("span", [
                  _c("i", { staticClass: "ico-user" }),
                  _vm._v(
                    _vm._s(_vm.userName) + "(" + _vm._s(_vm.licenseNum) + ")"
                  ),
                ]),
                _c("button", { staticClass: "btn-link-account" }, [
                  _vm._v(" 의사랑 계정 연결 완료 "),
                ]),
              ]),
              _c("copyright", [
                _vm._v("© "),
                _c("i", { staticClass: "logo-ubcare" }),
              ]),
            ],
            1
          ),
        ]),
      ]),
      _c("popup-verification", {
        ref: "popupVerification",
        on: { result: _vm.verifyResult },
      }),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title-bar" }, [
      _c("h1", [_vm._v("닥터인포")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("p", { staticClass: "password-txt" }, [
          _vm._v("비밀번호 오류 횟수 초과로"),
          _c("br"),
          _vm._v(" 본인 인증이 필요합니다"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "password-txt" }, [
        _vm._v(" 간편 비밀번호"),
        _c("br"),
        _vm._v("6자리 숫자를 재설정 합니다 "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("p", { staticClass: "password-txt" }, [
        _vm._v(" 비밀번호 확인을 위해 한 번 더 입력해 주세요. "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }