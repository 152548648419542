<template>
  <div class="drPickWrap">
    <div class="title-bar">
      <h1>닥터인포</h1>
    </div>
    <section class="else-wrap">
      <button
        v-if="!save"
        class="btn-back"
        @click="historyBack"
      >
        뒤로
      </button>

      <div class="login-wrap">
        <h2>닥터인포</h2>

        <div
          v-if="step === 1"
          class="login-write"
        >
          <ul>
            <li>
              <p class="password-txt">비밀번호 오류 횟수 초과로<br>
                본인 인증이 필요합니다</p>
            </li>
          </ul>
        </div>

        <button
          v-if="step === 1"
          class="btn-phone-verification"
          @click.prevent="openVerification"
        >
          휴대폰 인증
        </button>

        <div
          v-else-if="step === 2"
          class="login-write"
        >
          <ul v-if="password === null || password.length < 6">
            <li>
              <p class="password-txt">
                간편 비밀번호<br>6자리 숫자를 재설정 합니다
              </p>
            </li>
            <li style="margin-bottom: 162px">
              <label class="hidden">비밀번호</label>
              <div class="text-wrap">
                <input
                  v-model="password"
                  v-number
                  v-focus
                  type="password"
                  maxlength="6"
                >
              </div>
            </li>
          </ul>

          <ul v-else-if="password !== null && password.length == 6">
            <li>
              <p class="password-txt">
                비밀번호 확인을 위해 한 번 더 입력해 주세요.
              </p>
            </li>
            <li style="margin-bottom: 162px">
              <label class="hidden">비밀번호</label>
              <div class="text-wrap">
                <input
                  v-model="checkPassword"
                  v-number
                  v-focus
                  type="password"
                  maxlength="6"
                  :class="errorMessage ? 'error' : 'inputPassword'"
                  :readonly="save ? true : false"
                  @keyup="matchPassword"
                >
              </div>
            </li>
          </ul>

          <div
            v-if="errorMessage"
            class="message-box"
          >
            <p><i />{{ errorMessage }}</p>
          </div>
        </div>

        <div class="login-txt">
          <div>
            <span><i class="ico-user" />{{ userName }}({{ licenseNum }})</span>
            <button
              class="btn-link-account"
            >
              의사랑 계정 연결 완료
            </button>
          </div>
          <copyright>&copy; <i class="logo-ubcare" /></copyright>
        </div>
      </div>
    </section>

    <popup-verification
      ref="popupVerification"
      @result="verifyResult"
    />

    <alert-dialog :options="alertProps" />
  </div>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import PopupVerification from '@/views/apps/popup/PopupVerification.vue'

export default {
  components: {
    PopupVerification,
  },
  data() {
    return {
      step: 1,
      password: null,
      checkPassword: null,
      errorMessage: null,
      save: false
    }
  },
  computed: {
    ...mapGetters({
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
      licenseNum: 'infoData/getLicenseNum',
      userName: 'infoData/getUserName',
    }),
  },
  created() {
    if (this.shaEnLicenseNum === null) {
      this.showAlertDialog('잘못된 접근입니다.')

      this.$router.push({ name: 'app-main' })
    }
  },
  methods: {
    verifyResult() {
      this.step = 2
    },
    openVerification() {
      this.$refs.popupVerification.open()
    },
    matchPassword() {
      if (this.checkPassword !== null) {
        if (this.checkPassword.length < 6) this.errorMessage = null
        else if (this.checkPassword.length === 6 && this.password !== this.checkPassword) this.errorMessage = '비밀번호가 일치하지 않습니다.'
        else if (this.checkPassword.length === 6 && this.password === this.checkPassword) {
          this.errorMessage = null

          this.savePassword()
        }
      }
    },
    savePassword() {
      axios.put('/fu/user/pw', {
        loginId: this.shaEnLicenseNum,
        password: this.password,
      })
        .then(() => {
          this.save = true

          this.showAlertDialog('비밀번호가 변경되었습니다.', () => {
            this.$store.commit('util/setAccess', true)

            this.$router.push({ name: 'app-main' })
          })
        })
        .catch(() => {
          this.showAlertDialog('비밀번호 변경 중 오류가 발생했습니다.')

          this.password = null
          this.checkPassword = null
        })
    },
    historyBack() {
      if (this.step === 1) this.$router.push({ name: 'app-main' })
      else if (this.step === 2) this.step = 1
    }
  },
}
</script>
